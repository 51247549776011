import React from "react";
import { Route, Redirect } from "react-router-dom";

import auth from "../services/authServices";

//Route protetta (richiede login)

const ProtectedRoute = ({ component: Component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.getUserInfo()) return <Redirect to="/login" />;
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
