import React, { useEffect } from "react";
import auth from "../../services/authServices";

const Logout = () => {
  useEffect(() => {
    //Esegue il logout e carica direttamente la route di login
    auth.logout();

    window.location = "/login";
  });

  return null;
};

export default React.memo(Logout);
