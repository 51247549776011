import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import acceptLanguage from "accept-language";
import { IntlProvider } from "react-intl";

import store from "./redux/store/index";
import Routes from "./routes/routes";
import auth from "./services/authServices";
import messages from "./i18n/messages";
import localeChanger from "./i18n/localeChanger";

acceptLanguage.languages(["it-IT", "en-GB", "de-DE"]);

const App = () => {
  const [locale, setLocale] = useState("en-GB");

  //Montaggio componente (solo una volta)
  useEffect(() => {
    /* Rileva la locale da utilizzare.
         Se l'utente è loggato, verrà utilizzata la locale dell'utente, altrimenti cerca di rilevarla dal browser.
         Se tutti i tentativi di rilevare la locale falliscono, mette di default l'inglese.
         */
    const userInfo = auth.getUserInfo();
    if (userInfo && userInfo.languageCode) {
      //Utente loggato (al momento per l'utente è disponibile solo la lingua, pertanto la locale va composta)
      const userLanguage = userInfo.languageCode.toLowerCase();
      switch (userLanguage) {
        case "it":
          setLocale("it-IT");
          break;
        case "de":
          setLocale("de-DE");
          break;
        default:
          setLocale("en-GB");
          break;
      }

      return;
    }

    if (window.navigator.language) {
      //Locale dal browser
      //Per il momento rileva solo la lingua (primi due caratteri). In futuro si può valutare
      //se gestire la locale completa con il paese.
      setLocale(window.navigator.language);
      return;
    }

    setLocale("en");
  }, []);

  //Callback per il cambio lingua
  const changeLocale = (newLocale) => {
    setLocale(newLocale);
  };
  //Registra la locale corrente e la funzione di callback nel locale changer globale
  localeChanger.setCurrentLocale(locale);
  localeChanger.registerLocaleChanger(changeLocale);

  return (
    //Redux è utilizzato per la gestione del template grafico (che veniva già preconfigurato) e
    //di alcuni oggetti di stato globali (cross-servizio), come la lista di consegna.
    //Per il resto, ogni servizio è indipendente e gestisce il proprio stato in maniera autonoma.
    <Provider store={store}>
      <IntlProvider
        locale={locale}
        messages={messages[locale.replace("-", "_")]}
      >
        <Routes />
        <ReduxToastr
          timeOut={5000}
          newestOnTop={true}
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </IntlProvider>
    </Provider>
  );
};

export default App;
