import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import {
  landing as landingRoutes,
  purchaseOrders as purchaseOrdersRoutes,
  inboundDeliveries as inboundDeliveriesRoutes,
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Login from "../pages/auth/login";
import Logout from "../pages/auth/logout";
import SessionExpired from "../pages/auth/sessionExpired";
import ProtectedRoute from "./protectedRoute";
import ScrollToTop from "../components/ScrollToTop";
import NotFoundRoute from "./notFoundRoute";
import auth from "../services/authServices";

// Componente di implementazione instradamenti

const userInfo = auth.getUserInfo();

const childRoutes = (Layout, routes) =>
  routes.map(
    (
      { children, path, component: Component, userPermission, variant },
      index
    ) => {
      //Gestione autorizzazione utente associata.
      //Se non c'è l'autorizzazione, la route viene sostituita con la route "Not Found"
      if (userPermission) {
        if (
          !userInfo ||
          !userInfo.permissions ||
          !userInfo.permissions[userPermission]
        )
          return <NotFoundRoute key={index} />;
      }

      if (children) {
        // Route item with children
        return children.map(
          ({ path, component: Component, variant }, index) => {
            return (
              <ProtectedRoute
                key={index}
                path={path}
                exact
                render={(props) => (
                  <Layout>
                    <Component {...props} {...variant} />
                  </Layout>
                )}
              />
            );
          }
        );
      } else {
        // Route item without children
        return (
          <ProtectedRoute
            key={index}
            path={path}
            exact
            render={(props) => (
              <Layout>
                <Component {...props} {...variant} />
              </Layout>
            )}
          />
        );
      }
    }
  );

const Routes = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Switch>
        <Route
          path="/login"
          render={() => (
            <AuthLayout>
              <Login />
            </AuthLayout>
          )}
        />
        <Route path="/logout" component={Logout} />
        <Route
          path="/sessionexpired"
          render={() => (
            <AuthLayout>
              <SessionExpired />
            </AuthLayout>
          )}
        />
        <Redirect from="/TGOS2" to="/" />
        {childRoutes(DashboardLayout, landingRoutes)}
        {childRoutes(DashboardLayout, purchaseOrdersRoutes)}
        {childRoutes(DashboardLayout, inboundDeliveriesRoutes)}
        <NotFoundRoute />
      </Switch>
    </ScrollToTop>
  </BrowserRouter>
);

export default Routes;
