import React, { useState } from "react";
import { Input, Label, Badge } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";

import auth from "../../services/authServices";
import ConfirmationModal from "../../components/dialogs/confirmationModal";

// Componente per pannello laterale - Profilo utente

const UserProfilePanel = () => {
  const [confirmDialog, setConfirmDialog] = useState(false);

  const userInfo = auth.getUserInfo();
  const customerNo = userInfo.customerNo
    ? userInfo.customerNo.replace(/^0+/, "").trim()
    : "";
  const supplierNo = userInfo.supplierNo
    ? userInfo.supplierNo.replace(/^0+/, "").trim()
    : "";

  //Elimina i dati locali dell'applicazione ed esegue il logout
  const clearLocalData = () => {
    localStorage.clear();
    window.location = "/login";
  };

  return (
    <React.Fragment>
      <div className="settings-section">
        <div className="settings-layouts">
          <span className="settings-layouts-item">
            <Label className="mb-0 mt-1">
              <FormattedMessage id="userProfilePanel.username" />
            </Label>
            <Input
              type="username"
              name="username"
              readOnly={true}
              value={userInfo.userName}
            />
          </span>

          <span className="settings-layouts-item">
            <Label className="mb-0 mt-1">
              <FormattedMessage id="userProfilePanel.commonName" />
            </Label>
            <Input
              type="title"
              name="title"
              readOnly={true}
              value={userInfo.commonName}
            />
          </span>

          {customerNo ? (
            <span className="settings-layouts-item">
              <Label className="mb-0 mt-1">
                <FormattedMessage id="userProfilePanel.customerNo" />
              </Label>
              <Input
                type="customerNo"
                name="customerNo"
                readOnly={true}
                value={customerNo}
              />
            </span>
          ) : null}

          {supplierNo ? (
            <span className="settings-layouts-item">
              <Label className="mb-0 mt-1">
                <FormattedMessage id="userProfilePanel.supplierNo" />
              </Label>
              <Input
                type="supplierNo"
                name="supplierNo"
                readOnly={true}
                value={userInfo.supplierNo.replace(/^0+/, "")}
              />
            </span>
          ) : null}

          <span>
            <Label className="mb-0 mt-3">
              <FormattedMessage id="userProfilePanel.permissions" />
            </Label>
            <div>
              {userInfo.permissions.isAdmin ? (
                <Badge color={"danger"} className="badge" size={18}>
                  <FormattedMessage id="permissions.administrator" />
                </Badge>
              ) : (
                <React.Fragment>
                  {userInfo.permissions.viewPurchaseOrders ? (
                    <Badge color="info" className="badge mr-1" size={18}>
                      <FormattedMessage id="permissions.viewPurchaseOrders" />
                    </Badge>
                  ) : (
                    ""
                  )}
                  {userInfo.permissions.confirmPurchaseOrders ? (
                    <Badge color="info" className="badge mr-1" size={18}>
                      <FormattedMessage id="permissions.confirmPurchaseOrders" />
                    </Badge>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              )}
            </div>
          </span>
        </div>
      </div>
      <div className="settings-section">
        <Button color="danger" block onClick={() => setConfirmDialog(true)}>
          <span className="align-middle">
            <FormattedMessage id="userProfilePanel.clearLocalData" />
          </span>
        </Button>
      </div>
      <ConfirmationModal
        titleId="userProfilePanel.clearLocalData.confirmation.title"
        bodyId="userProfilePanel.clearLocalData.confirmation.body"
        isOpen={confirmDialog}
        onConfirm={clearLocalData}
        onCancel={() => setConfirmDialog(false)}
      />
    </React.Fragment>
  );
};

export default UserProfilePanel;
