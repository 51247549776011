/* Modulo di utility utilizzato come "escamotage" per rendere disponibile a livello globale
   la funzione di modifica della locale. In sostanza non è altro che un "holder" del puntatore
   alla funzione, che può essere messo a disposizione di tutti i componenti che necessitano cambiare
   la locale dell'applicazione. Ciò evita di passare il puntatore di funzione attraverso tutta la
   catena di componenti React, rendendo il codice più ordinato e mantenibile. Si evita inoltre
   l'utilizzo di Redux.
   La locale è in ogni caso un'impostazione globale e, una volta cambiata, è necessario ricaricare
   (rirenderizzare) tutti i componenti dell'applicazione. */
let changeLocaleFunction = (newLocale) => {};
let currentLocale = "en-GB";

//Funzione per la registrazione della funzione di cambio locale (normalmente eseguita all'avvio dell'applicazione)
function registerLocaleChanger(newLocaleChanger) {
  changeLocaleFunction = newLocaleChanger;
}

//Funzione di modifica della locale
function changeLocale(newLocale) {
  changeLocaleFunction(newLocale);
  currentLocale = newLocale;
}

//Restituisce la locale corrente
//Questa funzione viene utilizzata dagli script normali, in quanto nei componenti React la locale corrente
//viene sempre rilevata utilizzando l'apposito hook useIntl().
function getCurrentLocale() {
  return currentLocale;
}

//Utilizzata per inizializzare il modulo con la locale corrente.
//Normalmente la locale corrente viene automaticamente impostata dal metodo changeLocale.
function setCurrentLocale(newLocale) {
  currentLocale = newLocale;
}

const localeChanger = {
  registerLocaleChanger,
  changeLocale,
  getCurrentLocale,
  setCurrentLocale,
};

export default localeChanger;
