// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_STICKY_TOGGLE = "SIDEBAR_STICKY_TOGGLE";
export const SIDEBAR_STICKY_ENABLE = "SIDEBAR_STICKY_ENABLE";
export const SIDEBAR_STICKY_DISABLE = "SIDEBAR_STICKY_DISABLE";

// Layout
export const LAYOUT_BOXED_TOGGLE = "LAYOUT_BOXED_TOGGLE";
export const LAYOUT_BOXED_ENABLE = "LAYOUT_BOXED_ENABLE";
export const LAYOUT_BOXED_DISABLE = "LAYOUT_BOXED_DISABLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

// Lista di consegna
export const SHIPPING_LIST_ADD = "SHIPPING_LIST_ADD";
export const SHIPPING_LIST_REMOVE = "SHIPPING_LIST_REMOVE";
export const SHIPPING_LIST_REMOVE_ALL = "SHIPPING_LIST_REMOVE_ALL";
export const SHIPPING_LIST_CHANGE_DATE = "SHIPPING_LIST_CHANGE_DATE";
export const SHIPPING_LIST_CHANGE_QUANTITY = "SHIPPING_LIST_CHANGE_QUANTITY";
