import React from "react";
import { Button } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

// Pannello laterale a comparsa generico.
// Lo stato di apertura e chiusura va gestito esternamente mediante il flag isOpen e la callback handleClose.
// Il componente da visualizzare va passato in panelToShow.

const SidePanel = ({
  title,
  panelToShow: PanelToShow,
  isOpen,
  handleClose,
}) => {
  /* Le classi CSS sono denominate "settings..." in quanto questa impostazione fa parte del template utilizzato, che prevede
       il pannello laterale per la gestione delle impostazioni.
       Nell'applicazione, tuttavia, il pannello laterale è utilizzato per la visualizzazione del profilo utente ed eventualmente
       (in futuro) per altre funzioni. */
  return (
    <div className={"settings " + (isOpen ? "open" : "")}>
      <div className="settings-panel">
        <div className="settings-content">
          <PerfectScrollbar>
            <div className="settings-title">
              <Button close onClick={handleClose} />
              <h4>{title}</h4>
            </div>

            <PanelToShow />

            <div className="settings-section">
              <Button color="primary" block size="lg" onClick={handleClose}>
                <span className="align-middle">
                  <FormattedMessage id="sidePanel.close" />
                </span>
              </Button>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
};

SidePanel.propTypes = {
  title: PropTypes.string,
  panelToShow: PropTypes.any.isRequired,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

export default SidePanel;
