import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => (
  <footer className="footer">
    <Container fluid>
      <Row className="text-muted">
        <Col className="text-left">
          <ul className="list-inline">
            <li className="list-inline-item">
              <span className="text-muted" href="#">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.tecnicagasket.com"
                >
                  Tecnica Gasket S.p.A.
                </a>{" "}
                | Via Foppe, 15 - 25030 Paratico (BS) | Tel. +39 035924445 |
                P.IVA IT01733490161 |{" "}
                <a href="mailto:mktg@tecnicagasket.com">
                  mktg@tecnicagasket.com
                </a>
              </span>
            </li>
          </ul>
        </Col>
        <Col xs lg="2" className="text-right">
          <p className="mb-0">
            &copy; {new Date().getFullYear()} -{" "}
            <span className="text-muted">Tecnica Gasket S.p.A.</span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
