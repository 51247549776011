import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import tglogo from "../../assets/img/tg/logo_tg_login.png";
import auth from "../../services/authServices";

// Form di login

const SessionExpired = () => {
  //Se l'utente è già autenticato, redirige all'applicazione principale
  if (auth.getUserInfo()) return <Redirect to="/" />;
  return (
    <div className="text-center mt-4">
      <img
        alt="Tecnica Gasket S.p.A."
        src={tglogo}
        className="img-responsive mb-3"
      />
      <p className="h2 font-weight-normal mt-4 mb-5">
        <FormattedMessage id="sessionexpired.title" />
      </p>
      <Link to="/">
        <Button color="primary" size="lg">
          <FormattedMessage id="sessionexpired.repeatLogin" />
        </Button>
      </Link>
    </div>
  );
};

export default React.memo(SessionExpired);
