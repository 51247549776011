import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { Button } from "reactstrap";

//Pagina di errore

//Elenco codici di errore supportati
const supportedErrorNumbers = ["404", "500"];

const Error = ({ errNumber }) => {
  const isSupportedError = supportedErrorNumbers.includes(errNumber);

  return (
    <div className="text-center">
      <h1 className="display-1 font-weight-bold">{errNumber}</h1>

      <p className="h1">
        {isSupportedError ? (
          <FormattedMessage id={`error.${errNumber}.title`} />
        ) : (
          <FormattedMessage id="error.unknown" />
        )}
      </p>
      <p className="h2 font-weight-normal mt-3 mb-4">
        {isSupportedError && (
          <FormattedMessage id={`error.${errNumber}.description`} />
        )}
      </p>
      <Link to="/">
        <Button color="primary" size="lg">
          <FormattedMessage id="error.backToApplication" />
        </Button>
      </Link>
    </div>
  );
};

export default React.memo(Error);
