import React from "react";
import { Route } from "react-router-dom";

import AuthLayout from "../layouts/Auth";
import Error from "../pages/error";

//Route specifica per la gestione di risorse non trovate.
//Viene creato un componente apposito in quanto la route è utilizzata in diversi punti.

const NotFoundRoute = () => {
  return (
    <Route
      render={() => (
        <AuthLayout>
          <Error errNumber="404" />
        </AuthLayout>
      )}
    />
  );
};

export default NotFoundRoute;
