import { toastr } from "react-redux-toastr";
import _ from "lodash";
import moment from "moment";

//Funzioni di utilità generale condivise nell'intera soluzione

//Visualizza un Toaster con le opzioni indicate, mediante react-redux-toastr
export function showToastr(
  toastrType,
  title,
  message,
  timeOut,
  showCloseButton,
  progressBar,
  position
) {
  const options = {
    timeOut,
    showCloseButton,
    progressBar,
    position,
  };

  const toastrInstance =
    toastrType === "info"
      ? toastr.info
      : toastrType === "warning"
      ? toastr.warning
      : toastrType === "error"
      ? toastr.error
      : toastr.success;

  toastrInstance(title, message, options);
}

//Converte la data passata in "formato SAP", ossia nel formato attesto da tutti i Web Services esposti da SAP
//che utilizzano, che è aaaammgg (es. 20150227).
export function toSAPDate(date) {
  const mmt = moment(date);
  return mmt.format("YYYYMMDD");
}

//Funzione di utility per localizzare le date nel formato generale prescelto per l'applicazione
export function localizeDate(date, intl) {
  return intl.formatDate(date, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}

/* Funzione inversa di toSAPDate, converte la data in "formato SAP" passata in un oggetto Date di JavaScript.
   In questo caso, però, la funzione supporta sia il formato YYYYMMDD che il formato YYYY-MM-DD in quanto i
   Web Services di SAP possono utilizzare uno o l'altro a seconda del parser JSON implementato. */
export function fromSAPDate(sapDate) {
  if (sapDate.length === 8) return moment(sapDate, "YYYYMMDD").toDate();
  if (sapDate.length === 10) return moment(sapDate, "YYYY-MM-DD").toDate();
  return;
}

//Compone i due metodi precedenti, ossia converte una data in "formato SAP" nella versione già localizzata
export function fromSAPDateLocalized(sapDate, intl) {
  return localizeDate(fromSAPDate(sapDate), intl);
}

//Funzione di paginazione array di elementi (standard)
export function paginate(items, pageNumber, pageSize) {
  const startIndex = (pageNumber - 1) * pageSize;
  return _(items).slice(startIndex).take(pageSize).value();
}

//Funzione per il parsing di valori numerici float considerando la locale
export function parseFloatLocalized(value, locale) {
  //Questa parte va rivista se si aggiungono altre locali. Al momento non si è trovato
  //un parser decente che sia localizzato. Al limite si può pensare di utilizzare cldr per
  //rilevare i separatori a seconda della locale e poi effettuare le opportune sostituzioni.
  let result;
  if (locale.substring(0, 2) === "en") {
    result = parseFloat(value.replace(/,/g, ""));
  } else {
    result = parseFloat(value.replace(/\./g, "").replace(/,/g, "."));
  }

  return result;
}

//Riceve una data e, se questa è nel passato, ritorna la data odierna, altrimenti la data passata.
export function fromTodayOn(date) {
  const today = new Date();
  return date >= today ? date : today;
}

//Funzione di utility che, data un'eccezione avvenuta durante la comunicazione con un Web Service,
//restituisce true se l'eccezione è di tipo inatteso.
export function isUnexpectedHttpException(exception) {
  //Se l'eccezione è null, ritorna true per default
  if (!exception) return true;
  //Se l'eccezione non ha un attributo "response", ritorna true (potrebbe non essere un'eccezione HTTP)
  if (!exception.response) return true;
  //Se l'eccezione non ha un attributo "response.status", ritorna true (improbabile ma previsto comunque)
  if (!exception.response.status) return true;

  //Gli errori inattesi sono quelli con HTTP stato inferiore a 400 o maggiore = a 500
  return exception.response.status < 400 || exception.response.status >= 500;
}
