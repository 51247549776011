import _ from "lodash";

import * as types from "../constants";
import { toSAPDate } from "../../utils/common";

const shippingListLSField = "shipping-list";
const storedShippingList = localStorage.getItem(shippingListLSField);
const initialState = storedShippingList ? JSON.parse(storedShippingList) : [];

//Filtra l'oggetto posizione OdA passato restituendo un oggetto con i soli attributi rilevanti per
//la lista di consegna/spedizione (per ragioni di pulizia e per evitare inutile occupazione di memoria).
function toShippingListItem(poi, deliveryDate) {
  //Rileva direttamente alcuni campi della posizione OdA
  let result = _.pick(
    poi,
    "formattedDocumentNo",
    "formattedItemNo",
    "DOCUMENT_NO",
    "ITEM_NO",
    "MATERIAL_NO",
    "ITEM_TEXT",
    "UOM"
  );
  result.DELIVERY_DATE = toSAPDate(deliveryDate);

  //Ai fini dell'aggiunta della posizione OdA alla lista di consegna, l'oggetto deve avere la proprietà deliveryQuantities,
  //che è a sua volta un oggetto che contiene le quantità rilevanti per la creazione dell'avviso di consegna.
  //Tale oggetto viene utilizzato per elaborare la proposta di quantità da inserire nell'avviso e per eseguire altri controlli.
  result.QUANTITY = 0;
  //Se la quantità consegnabile è positiva, la imposta come proposta,
  //altrimenti la proposta rimane 0 (andrà aggiunta manualmente dall'utente)
  if (poi.deliveryQuantities.deliverableQuantity > 0)
    result.QUANTITY = poi.deliveryQuantities.deliverableQuantity;

  //La proprietà deliveryQuantities viene trasferita alla posizione di lista di consegna.
  result.deliveryQuantities = poi.deliveryQuantities;

  //Infine, imposta la chiave (n. ordine + posizione), necessaria per gestire e distinguere gli elementi nelle tabelle
  result.key = `${result.DOCUMENT_NO}${result.ITEM_NO}`;

  return result;
}

export default function reducer(state = initialState, actions) {
  let newShippingList = [];

  switch (actions.type) {
    case types.SHIPPING_LIST_ADD:
      //Aggiunge il nuovo elemento alla lista, convertendolo appositamente e assegnandovi la data di consegna
      newShippingList = [
        ...state,
        ...actions.payload.purchaseOrders.map((poi) =>
          toShippingListItem(poi, actions.payload.deliveryDate)
        ),
      ];
      localStorage.setItem(
        shippingListLSField,
        JSON.stringify(newShippingList)
      );
      return newShippingList;

    case types.SHIPPING_LIST_REMOVE:
      //Filtra la nuova lista escludendo le posizioni che vanno rimosse
      newShippingList = state.filter(
        (listItem) =>
          actions.payload.findIndex(
            (toRemove) =>
              toRemove.DOCUMENT_NO === listItem.DOCUMENT_NO &&
              toRemove.ITEM_NO === listItem.ITEM_NO
          ) === -1
      );
      localStorage.setItem(
        shippingListLSField,
        JSON.stringify(newShippingList)
      );
      return newShippingList;

    case types.SHIPPING_LIST_REMOVE_ALL:
      localStorage.setItem(
        shippingListLSField,
        JSON.stringify(newShippingList)
      );
      //Elimina tutti gli elementi della lista
      return newShippingList;

    case types.SHIPPING_LIST_CHANGE_DATE:
      //Aggiunge il nuovo elemento alla lista, convertendolo appositamente e assegnandovi la data di consegna
      newShippingList = [...state];

      actions.payload.purchaseOrders.forEach((poi) => {
        const poiFound = newShippingList.find(
          (item) =>
            item.DOCUMENT_NO === poi.DOCUMENT_NO && item.ITEM_NO === poi.ITEM_NO
        );
        if (poiFound)
          poiFound.DELIVERY_DATE = toSAPDate(actions.payload.newDeliveryDate);
      });

      localStorage.setItem(
        shippingListLSField,
        JSON.stringify(newShippingList)
      );
      return newShippingList;

    case types.SHIPPING_LIST_CHANGE_QUANTITY:
      //Rimappa la nuova lista modificando la quantità della posizione indicata
      newShippingList = state.map((listItem) => {
        const po = actions.payload.purchaseOrder;
        if (
          po.DOCUMENT_NO === listItem.DOCUMENT_NO &&
          po.ITEM_NO === listItem.ITEM_NO
        )
          listItem.QUANTITY = actions.payload.newDeliveryQuantity;

        return listItem;
      });
      localStorage.setItem(
        shippingListLSField,
        JSON.stringify(newShippingList)
      );
      return newShippingList;
    default:
      return state;
  }
}
