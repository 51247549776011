import async from "../components/Async";
import { ShoppingCart as CartIcon, Truck as TruckIcon } from "react-feather";

//import Blank from "../pages/blank";

// Definizione instradamenti per il menù laterale

// Dashboard principale
const LandingDashboard = async(() =>
  import("../pages/serviceDashboards/landing")
);

// Dashboard Ordini di acquisto
const PurchaseOrdersDashboard = async(() =>
  import("../pages/serviceDashboards/purchaseOrders/purchaseOrders")
);

// Dashboard Lista di consegna
const ShippingListDashboard = async(() =>
  import("../pages/serviceDashboards/inboundDeliveries/shippingList")
);

// Dashboard Avvisi di consegna (o consegne in ingresso)
const InboundDeliveriesDashboard = async(() =>
  import("../pages/serviceDashboards/inboundDeliveries/inboundDeliveries")
);

// Landing page
const landingRoutes = {
  path: "/",
  i18nId: "services.onlineServices",
  containsHome: true,
  component: LandingDashboard,
  children: null,
};

// Menù fornitore - Ordini di acquisto
const purchaseOrdersRoutes = {
  path: "/pos",
  i18nId: "service.pos",
  headerI18nId: "services.supplier",
  icon: CartIcon,
  userPermission: "viewPurchaseOrders",
  children: [
    {
      path: "/pos/toconfirm",
      i18nId: "service.pos.toConfirm",
      component: PurchaseOrdersDashboard,
      variant: { toConfirm: true },
    },
    {
      path: "/pos/confirmed",
      i18nId: "service.pos.confirmed",
      component: PurchaseOrdersDashboard,
      variant: { toConfirm: false },
    },
  ],
};

// Menù fornitore - Avvisi di consegna
const inboundDeliveriesRoutes = {
  path: "/indlv",
  i18nId: "service.idl",
  icon: TruckIcon,
  userPermission: "viewInboundDeliveries", //Da rivedere
  children: [
    {
      //Qui magari mettere un badge con il numero di OdA inseriti nella lista
      badgeColor: "primary",
      path: "/indlv/shippinglist",
      i18nId: "service.idl.shippingList",
      component: ShippingListDashboard,
    },
    {
      path: "/indlv/created",
      i18nId: "service.idl.created",
      component: InboundDeliveriesDashboard,
    },
  ],
};

/* Nota: se un domani l'attributo userPermission va gestito anche a livello di children, i punti da modificare sono:
 * - Metodo childRoutes in routes.jsx;  (per redirigere a not found)
 * - Metodo render in Sidebar.js; (per evitare che le route vengano visualizzate nel navigatore)
 */

// Esportazione gruppi di routes singoli
export const landing = [landingRoutes];
export const purchaseOrders = [purchaseOrdersRoutes];
export const inboundDeliveries = [inboundDeliveriesRoutes];

// Tutte le route in un array
const routes = [landingRoutes, purchaseOrdersRoutes, inboundDeliveriesRoutes];
export default routes;
