import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  FormFeedback,
} from "reactstrap";
import { Redirect } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import AlertModal from "../../components/dialogs/alertModal";
import tglogo from "../../assets/img/tg/logo_tg_login.png";
import auth from "../../services/authServices";

// Form di login

const Login = () => {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [logginIn, setLogginIn] = useState(false);
  const [errors, setErrors] = useState({});
  const [alertDialog, setAlertDialog] = useState(null);

  const intl = useIntl();

  //Validazione valore campo.
  const handleChange = (fieldName, value) => {
    //Aggiornamento valore nello stato (corretto o errato che sia, per dare il feedback all'utente)
    const newFormData = { ...formData };
    newFormData[fieldName] = value;
    setFormData(newFormData);

    const newErrors = { ...errors };

    //Validazioni
    //Obbligatorietà valore
    if (value.trim() === "") {
      newErrors[fieldName] = (
        <FormattedMessage id={`login.${fieldName}.placeholder`} />
      );
      setErrors(newErrors);
      return;
    }

    //Se la validazione ha successo, rimuove eventuali errori
    delete newErrors[fieldName];
    setErrors(newErrors);
  };

  //Visualizzazione dialog "password dimenticata"
  const showForgotPasswordAlert = () => {
    setAlertDialog({
      titleId: "login.forgotPassword",
      bodyId: "login.forgotPassword.details",
      color: "primary",
    });
  };

  //Gestisce il processo di login
  const doLogin = async (e) => {
    e.preventDefault();

    //Imposta lo stato di attesa
    setLogginIn(true);

    const { username, password } = formData;
    //Esecuzione login
    const result = await auth.login(username, password);

    //In caso di login fallito, genera un errore
    if (!result) {
      setAlertDialog({
        titleId: "login.failed",
        bodyId: "login.failed.details",
        color: "danger",
      });

      setFormData({ username: "", password: "" }); //Resetta i dati del form
    } else {
      //Se il login ha successo, la dashboard viene ricaricata completamente
      window.location = "/";
    }

    setLogginIn(false);
  };

  //Se l'utente è già autenticato, redirige all'applicazione
  if (auth.getUserInfo()) return <Redirect to="/" />;
  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <img
          alt="Tecnica Gasket S.p.A."
          src={tglogo}
          className="img-responsive mb-3"
        />
        <p className="lead">
          <FormattedMessage id="login.title" />
        </p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <div className="text-center"></div>
            <Form onSubmit={doLogin}>
              <FormGroup>
                <Label>
                  <FormattedMessage id="login.username" />
                </Label>
                <Input
                  bsSize="lg"
                  type="username"
                  name="username"
                  maxLength="50"
                  value={formData.username}
                  placeholder={intl.formatMessage({
                    id: "login.username.placeholder",
                  })}
                  onChange={(e) => handleChange("username", e.target.value)}
                  invalid={!!errors.username}
                />
                {errors.username ? (
                  <FormFeedback>{errors.username}</FormFeedback>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label>
                  <FormattedMessage id="login.password" />
                </Label>
                <Input
                  bsSize="lg"
                  type="password"
                  name="password"
                  maxLength="50"
                  value={formData.password}
                  placeholder={intl.formatMessage({
                    id: "login.password.placeholder",
                  })}
                  onChange={(e) => handleChange("password", e.target.value)}
                  invalid={!!errors.password}
                />
                {errors.password ? (
                  <FormFeedback>{errors.password}</FormFeedback>
                ) : null}
                <small>
                  <Button
                    color="link"
                    className="pl-0"
                    onClick={showForgotPasswordAlert}
                  >
                    <FormattedMessage id="login.forgotPasswordQuestion" />
                  </Button>
                </small>
              </FormGroup>
              <div className="text-center mt-3">
                <Button
                  color="primary"
                  size="lg"
                  type="submit"
                  disabled={
                    logginIn ||
                    Object.keys(errors).length !== 0 ||
                    formData.username.trim() === "" ||
                    formData.password.trim() === ""
                  }
                >
                  {logginIn ? (
                    <React.Fragment>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;&nbsp;
                      <FormattedMessage id="login.pleaseWait" />
                    </React.Fragment>
                  ) : (
                    <FormattedMessage id="login.login" />
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
      {alertDialog ? (
        <AlertModal
          titleId={alertDialog.titleId}
          bodyId={alertDialog.bodyId}
          color={alertDialog.color}
          isOpen={true}
          handleToggle={() => setAlertDialog(null)}
        />
      ) : null}
    </React.Fragment>
  );
};

export default Login;
