import messages_it from "./messageFiles/it.json";
import messages_en from "./messageFiles/en.json";
import messages_de from "./messageFiles/de.json";

//Collettore messaggi in lingua
const messages = {
  it_IT: messages_it,
  en_GB: messages_en,
  de_DE: messages_de,
};

export default messages;
