import axios from "axios";
import { sha256 } from "js-sha256";
import jwtDecode from "jwt-decode";

import config from "../config.json";

//Modulo per la gestione dell'autenticazione e autorizzazione

const authTokenLSField = "auth-token";

//Codice cliente e fornitore impersonati (solo per utente amministratore)
let impersonatedCustomerNo = null;
let impersonatedSupplierNo = null;

/* Esecuzione processo di login utente.
   Se ha successo, ritorna il JWT trasmesso dal server, altrimenti null. */
async function login(username, password) {
  //Crea l'hash SHA256 della password, necessario per chiamare il servizio di login
  const passwordSHA256 = sha256(password);

  try {
    //Richiama il servizio di login dell'API Gateway
    const response = await axios.post(config.general.endpoint_login, {
      username,
      passwordSHA256,
    });

    //Se la risposta non contiene il JWT (negli header), esce restituendo null
    if (!response || !response.headers || !response.headers["x-auth-token"])
      return;

    //Rileva il JWT, lo memorizza nel local storage e lo imposta nel modulo http
    const jwt = response.headers["x-auth-token"];
    localStorage.setItem(authTokenLSField, jwt);

    return true;

    //In caso di errori il login è fallito
  } catch (error) {}
  return false;
}

//Logout applicazione
function logout() {
  //Il logout semplicemente rimuove il token dal local storage
  localStorage.removeItem(authTokenLSField);
}

//Restituisce il JWT rilevato dall'archivio locale
function getJwt() {
  return localStorage.getItem(authTokenLSField);
}

//Rilevazione informazioni utente (dal JWT)
function getUserInfo() {
  try {
    const jwt = getJwt();
    const decodedJwt = jwtDecode(jwt);

    /* Controlla a priori che il JWT non sia scaduto e, qualora lo sia, rimuove il token dal local archive
       e rimanda alla pagina di login.
     */
    const dateNow = new Date();
    if (decodedJwt.exp * 1000 < dateNow.getTime()) {
      logout();
      window.location = "/login";
    }

    return decodedJwt;
  } catch (error) {
    return null;
  }
}

//Restituisce true se l'utente connesso ha l'autorizzazione indicata
function userHasPermission(perm) {
  const userInfo = getUserInfo();

  return (
    userInfo &&
    userInfo.permissions &&
    (userInfo.permissions[perm] || userInfo.permissions.isAdmin)
  );
}

/* Funzione di utility che restituisce true solo se è loggato un utente e questi non ha alcun tipo
 * di autorizzazione nell'applicazione.
 * Questa funzione va aggiornata ogniqualvolta viene aggiunto o rimosso un nuovo flag autorizzativo. */
function noPermissions() {
  //Se l'utente non è loggato, esce restituendo false
  const userInfo = getUserInfo();
  if (!userInfo) return false;

  //Non dovrebbe mai capitare, ma nel caso non esista la proprietà "permissions", significa che l'utente
  //non ha autorizzazioni, pertanto ritorna true
  if (!userInfo.permissions) return true;

  //Rileva tutte le possibili autorizzazioni
  if (
    !userInfo.permissions.isAdmin &&
    !userInfo.permissions.viewPurchaseOrders &&
    !userInfo.permissions.confirmPurchaseOrders
  )
    return true;

  return false;
}

function setImpersonatedCustomerNo(newImpersonatedCustomerNo) {
  impersonatedCustomerNo = newImpersonatedCustomerNo;
}

function getImpersonatedCustomerNo() {
  return impersonatedCustomerNo;
}

function setImpersonatedSupplierNo(newImpersonatedSupplierNo) {
  impersonatedSupplierNo = newImpersonatedSupplierNo;
}

function getImpersonatedSupplierNo() {
  return impersonatedSupplierNo;
}

const authServices = {
  login,
  logout,
  getJwt,
  getUserInfo,
  userHasPermission,
  noPermissions,
  setImpersonatedCustomerNo,
  getImpersonatedCustomerNo,
  setImpersonatedSupplierNo,
  getImpersonatedSupplierNo,
};

export default authServices;
