import React, { useState } from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap";
import { Settings, LogOut, User } from "react-feather";
import { FormattedMessage, useIntl } from "react-intl";

import auth from "../services/authServices";
import SidePanel from "../components/sidePanels/sidePanel";
import UserProfilePanel from "../components/sidePanels/userProfilePanel";
import localeChanger from "../i18n/localeChanger";

import gbFlag from "../assets/img/flags/gb.png";
import itFlag from "../assets/img/flags/it.png";
import deFlag from "../assets/img/flags/de.png";

const NavbarComponent = ({ dispatch }) => {
  const [userProfilePanelOpen, setUserProfilePanelOpen] = useState(false);

  const commonName = auth.getUserInfo().commonName;
  const handleUserProfilePanelClosure = () => setUserProfilePanelOpen(false);
  const intl = useIntl();
  const currentLocale = intl.locale;

  //Etichette opzioni lingua, tradotte a loro volta
  const languageOptions = {
    it: <FormattedMessage id="navbar.languageSelector.italian" />,
    en: <FormattedMessage id="navbar.languageSelector.english" />,
    de: <FormattedMessage id="navbar.languageSelector.german" />,
  };

  let selectedLanguageFlag;
  switch (currentLocale) {
    case "it-IT":
      selectedLanguageFlag = itFlag;
      break;
    case "de-DE":
      selectedLanguageFlag = deFlag;
      break;
    default:
      selectedLanguageFlag = gbFlag;
  }

  return (
    <Navbar color="white" light expand>
      <SidePanel
        title={intl.formatMessage({ id: "userProfilePanel.title" })}
        panelToShow={UserProfilePanel}
        isOpen={userProfilePanelOpen}
        handleClose={handleUserProfilePanelClosure}
      />

      <span
        className="sidebar-toggle d-flex mr-2"
        onClick={() => {
          dispatch(toggleSidebar());
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      <Collapse navbar>
        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown nav inNavbar className="mr-2">
            <DropdownToggle
              nav
              caret
              className="nav-flag"
              id="languageSelector"
            >
              <img
                src={selectedLanguageFlag}
                alt={languageOptions[currentLocale]}
              />
              <UncontrolledTooltip placement="right" target="languageSelector">
                <FormattedMessage id="navbar.languageSelector.tooltip" />
              </UncontrolledTooltip>
            </DropdownToggle>

            <DropdownMenu right>
              <DropdownItem onClick={() => localeChanger.changeLocale("it-IT")}>
                <img
                  src={itFlag}
                  alt={languageOptions["it"]}
                  width="20"
                  className="align-middle mr-1"
                />
                <span className="align-middle">{languageOptions["it"]}</span>
              </DropdownItem>
              <DropdownItem onClick={() => localeChanger.changeLocale("en-GB")}>
                <img
                  src={gbFlag}
                  alt={languageOptions["en"]}
                  width="20"
                  className="align-middle mr-1"
                />
                <span className="align-middle">{languageOptions["en"]}</span>
              </DropdownItem>
              <DropdownItem onClick={() => localeChanger.changeLocale("de-DE")}>
                <img
                  src={deFlag}
                  alt={languageOptions["de"]}
                  width="20"
                  className="align-middle mr-1"
                />
                <span className="align-middle">{languageOptions["de"]}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          <UncontrolledDropdown nav inNavbar>
            <span className="d-inline-block d-sm-none">
              <DropdownToggle nav caret>
                <Settings size={18} className="align-middle" />
              </DropdownToggle>
            </span>
            <span className="d-none d-sm-inline-block">
              <DropdownToggle nav caret>
                <span className="text-dark">{commonName}</span>
              </DropdownToggle>
            </span>
            <DropdownMenu right>
              <DropdownItem onClick={() => setUserProfilePanelOpen(true)}>
                <User size={18} className="align-middle mr-2" />
                <FormattedMessage id="navbar.userMenu.userProfile" />
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag="a" href="/logout">
                <LogOut size={18} className="align-middle mr-2" />
                <FormattedMessage id="navbar.userMenu.logOut" />
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default connect((store) => ({
  app: store.app,
}))(NavbarComponent);
