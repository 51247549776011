import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { Badge, Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import routes from "../routes/index";
import auth from "../services/authServices";

import tglogo from "../assets/img/tg/logo_tg_sidebar.png";

//Navigatore laterale

const userInfo = auth.getUserInfo();

const SidebarCategory = withRouter(
  ({
    i18nId,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    to,
  }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <Icon size={18} className="align-middle mr-3" />
          <span className="align-middle">
            <FormattedMessage id={i18nId} />
          </span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({
    i18nId,
    badgeColor,
    badgeText,
    icon: Icon,
    location,
    to,
    containsHome,
  }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname === path ? "active" : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
          {containsHome ? (
            <strong>
              <FormattedMessage id={i18nId} />
            </strong>
          ) : (
            <FormattedMessage id={i18nId} />
          )}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //Funzione di utility per sostituire dinamicamente i valori degli eventuali badge nelle voci di navigazione
  //in base a variabili di stato globali. La funzione è estremamente specifica e mappa determinati percorsi di
  //navigazione a variabili di stato, a seconda delle esigenze dell'applicazione.
  dynamicReplaceBadgeText = (routePath, badgeText) => {
    switch (routePath) {
      case "/indlv/shippinglist": //Voce lista di consegna
        return this.props.shippingList.length; //Ritorna il conteggio elementi lista di consegna

      default:
        //In tutti gli altri casi, ritorna il testo così com'è
        return badgeText;
    }
  };

  toggle = (index) => {
    // Collapse all elements
    Object.keys(this.state).forEach(
      (item) =>
        this.state[index] ||
        this.setState(() => ({
          [item]: false,
        }))
    );

    // Toggle selected element
    this.setState((state) => ({
      [index]: !state[index],
    }));
  };

  UNSAFE_componentWillMount() {
    /* Open collapse element that matches current url */
    const pathName = this.props.location.pathname;

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/" ? true : false;

      this.setState(() => ({
        [index]: isActive || isOpen || isHome,
      }));
    });
  }

  render() {
    const { sidebar } = this.props;

    return (
      <nav
        className={
          "sidebar" +
          (!sidebar.isOpen ? " toggled" : "") +
          (sidebar.isSticky ? " sidebar-sticky" : "")
        }
      >
        <div className="sidebar-content">
          <PerfectScrollbar>
            <a
              className="sidebar-brand"
              href="http://www.tecnicagasket.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="align-middle">
                <img
                  alt="Tecnica Gasket S.p.A."
                  src={tglogo}
                  className="img-responsive mt-2"
                  style={{ textAlign: "center", verticalAlign: "baseline" }}
                />
              </span>
            </a>

            <ul className="sidebar-nav">
              {routes.map((category, index) => {
                //Gestione autorizzazione utente associata.
                //Se non c'è l'autorizzazione, la route viene sostituita con la route "Not Found"
                if (category.userPermission) {
                  if (
                    !userInfo ||
                    !userInfo.permissions ||
                    !userInfo.permissions[category.userPermission]
                  )
                    return null;
                }
                return (
                  <React.Fragment key={index}>
                    {category.headerId ? (
                      <li className="sidebar-header">
                        <FormattedMessage id={category.headerI18nId} />
                      </li>
                    ) : null}

                    {category.children ? (
                      <SidebarCategory
                        i18nId={category.i18nId}
                        badgeColor={category.badgeColor}
                        badgeText={this.dynamicReplaceBadgeText(
                          category.path,
                          category.badgeText
                        )}
                        icon={category.icon}
                        to={category.path}
                        isOpen={this.state[index]}
                        onClick={() => this.toggle(index)}
                      >
                        {category.children.map((route, index) => (
                          <SidebarItem
                            key={index}
                            i18nId={route.i18nId}
                            to={route.path}
                            badgeColor={route.badgeColor}
                            badgeText={this.dynamicReplaceBadgeText(
                              route.path,
                              route.badgeText
                            )}
                          />
                        ))}
                      </SidebarCategory>
                    ) : (
                      <SidebarItem
                        i18nId={category.i18nId}
                        to={category.path}
                        icon={category.icon}
                        badgeColor={category.badgeColor}
                        badgeText={this.dynamicReplaceBadgeText(
                          category.path,
                          category.badgeText
                        )}
                        containsHome={category.containsHome}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </ul>
          </PerfectScrollbar>
        </div>
      </nav>
    );
  }
}

export default withRouter(
  connect((store) => ({
    sidebar: store.sidebar,
    layout: store.layout,
    shippingList: store.shippingList,
  }))(Sidebar)
);
