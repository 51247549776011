import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

/* Componente finestra di PopUp modale per gestione di richieste di conferma da parte dell'utente.
   Dispone di due pulsanti, "Ok" e "Annulla", ai quali corrispondono due callback da passare (la callback 
   di "Annulla" viene anche utilizzata per il toggle). I colori della dialog sono predefiniti.
   Il componente è stateless, in quanto lo stato di apertura e chiusura dev'essere gestito dal
   componente chiamante. */

const ConfirmationModal = ({
  titleId,
  bodyId,
  values,
  isOpen,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered>
      <ModalHeader toggle={onCancel}>
        <FormattedMessage id={titleId} values={values} />
      </ModalHeader>
      <ModalBody className="text-center m-3">
        <p className="mb-0">
          <FormattedMessage id={bodyId} values={values} />
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onConfirm}>
          <FormattedMessage id="confirmationModal.ok" />
        </Button>{" "}
        <Button color="secondary" onClick={onCancel}>
          <FormattedMessage id="confirmationModal.cancel" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  titleId: PropTypes.string.isRequired,
  bodyId: PropTypes.string.isRequired,
  values: PropTypes.object,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default React.memo(ConfirmationModal);
