import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

/* Componente finestra di PopUp modale per gestione di avvisi generici. Ha un solo pulsante di chiusura.
   Il componente è stateless, in quanto lo stato di apertura e chiusura dev'essere gestito dal
   componente chiamante. */

const AlertModal = ({
  titleId,
  bodyId,
  values,
  color,
  isOpen,
  handleToggle,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={handleToggle} centered>
      <ModalHeader toggle={handleToggle}>
        <FormattedMessage id={titleId} values={values} />
      </ModalHeader>
      <ModalBody className="text-center m-3">
        <p className="mb-0">
          <FormattedMessage id={bodyId} values={values} />
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color={color} onClick={handleToggle}>
          <FormattedMessage id="alertModal.close" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

AlertModal.propTypes = {
  titleId: PropTypes.string.isRequired,
  bodyId: PropTypes.string.isRequired,
  values: PropTypes.object,
  color: PropTypes.string,
  isOpen: PropTypes.bool,
  handleToggle: PropTypes.func.isRequired,
};

export default React.memo(AlertModal);
